<template>
  <div>
    <edit-space-configurations
      v-if="showEditSpaceConfigurations"
      :showModal="showEditSpaceConfigurations"
      :space="space"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showEdit="true"
      :editFunction="setShowEditSpaceConfigurations"
    >
      <template v-slot:title>Space layouts</template>
    </ui-section-header>

    <table class="table is-fullwidth is-striped is-narrow">
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>Layout</th>
          <th class="has-text-right">Min. seats</th>
          <th class="has-text-right">Max. seats</th>
          <th class="has-text-right">Is public</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="configuration in space.Settings" :key="configuration.Id">
          <td>
            <ui-svg-setting :settingId="configuration.SettingId" />
          </td>
          <td>{{ configuration.SettingId | getSettingName }}</td>
          <td class="has-text-right">{{ configuration.MinSeats }}</td>
          <td class="has-text-right">{{ configuration.MaxSeats }}</td>
          <td class="has-text-right">
            <font-awesome-icon
              v-if="configuration.IsPublic"
              :icon="['fas', 'check']"
              class="has-text-success"
            />
            <font-awesome-icon
              v-if="!configuration.IsPublic"
              :icon="['fas', 'times']"
              class="has-text-danger"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const ModalEditSpaceConfigurations = () =>
  import('@/components/Spaces/ModalEditSpaceConfigurations')

export default {
  components: {
    'edit-space-configurations': ModalEditSpaceConfigurations,
  },

  data() {
    return {
      showEditSpaceConfigurations: false,
    }
  },

  computed: {
    ...mapState('spaceStore', ['space']),
  },

  methods: {
    setShowEditSpaceConfigurations() {
      this.showEditSpaceConfigurations = true
    },

    hideModal() {
      this.showEditSpaceConfigurations = false
    },
  },
}
</script>

<style lang="scss" scoped>
.table {
  tr {
    td {
      vertical-align: middle;
    }
  }
}
</style>
